<template>

    <form class="form-signin">
        <b-card title="Rastreamos.Mailer" sub-title="Autenticação">
            <div class="text-center mb-4" style="font-size: 50px">
                <img src="/logo.png">
            </div>

            <div class="form-label-group">
                <label for="inputEmail">E-MAIL</label>
                <input type="email" v-model="dado.username"
                       id="inputEmail" class="form-control"
                       placeholder="Email" required autofocus>
            </div>

            <div class="form-label-group">
                <label for="inputPassword">SENHA</label>
                <input type="password" v-model="dado.password"
                       id="inputPassword" class="form-control"
                       placeholder="Senha" required>
            </div>

            <button :disabled="!dado.username || ! dado.password"
                    @click.stop="acessar()"
                    class="btn btn-lg btn-primary btn-block" >
                ACESSAR
            </button>
        </b-card>
    </form>

</template>

<script>
    import axios from "axios";

    export default {
        name: "Login",
        data () {
            return {
                dado: {
                    username: null,
                    password: null
                }
            }
        },
        methods: {
            async acessar () {
                let user = await axios.post("/auth", this.dado);
                if (user.data.code === 200) {
                    axios.defaults.headers.common['Authorization'] = "JWT " + user.data.payload.token;
                    window.sessionStorage.setItem("token", user.data.payload.token);
                    this.$router.push("/");
                }
            }
        }
    }
</script>

<style scoped>

    .form-signin {
        width: 100%;
        max-width: 420px;
        padding: 15px;
        margin: auto;
    }

    .form-label-group {
        position: relative;
        margin-bottom: 1rem;
    }



    .form-label-group input::-webkit-input-placeholder {
        color: transparent;
    }

    .form-label-group input:-ms-input-placeholder {
        color: transparent;
    }

    .form-label-group input::-ms-input-placeholder {
        color: transparent;
    }

    .form-label-group input::-moz-placeholder {
        color: transparent;
    }

    .form-label-group input::placeholder {
        color: transparent;
    }

    /* Fallback for Edge
    -------------------------------------------------- */
    @supports (-ms-ime-align: auto) {
        .form-label-group > label {
            display: none;
        }
        .form-label-group input::-ms-input-placeholder {
            color: #777;
        }
    }

    /* Fallback for IE
    -------------------------------------------------- */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .form-label-group > label {
            display: none;
        }
        .form-label-group input:-ms-input-placeholder {
            color: #777;
        }
    }
</style>
