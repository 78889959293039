import Vue from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import axios from "axios";
import VueSwal from 'vue-swal'
import './assets/bootstrap.min.css';
import Navigator from './components/navigator';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAt, faBuilding, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAt, faBuilding, faHome);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueSwal);
Vue.component('navigator', Navigator);
Vue.use(BootstrapVue);
Vue.config.productionTip = true;

//axios.defaults.baseURL = 'http://gestormail.portalgovernamental.com.br/';
axios.defaults.baseURL = '/';
//axios.defaults.baseURL = 'http://localhost:8000';
//axios.defaults.baseURL = 'http://localhost:5500';

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
