<template>
    <div class="home">
        <navigator/>

        <div class="container" style="margin-top: 10px">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#/">
                    <font-awesome-icon icon="home" size="1x"/> HOME</a>
                </li>
                <li class="breadcrumb-item active">
                    <font-awesome-icon icon="building" size="1x"/>  EMPRESAS
                </li>
            </ol>
            <a href="#/empresa" class="btn btn-success">
                NOVA EMPRESA
            </a>
            <br/><br/>
            <list-empresas/>
        </div>

    </div>
</template>

<script>
    import ListEmpresas from "../components/ListEmpresas";

    export default {
        name: "Empresas",
        components: {
            ListEmpresas
        }
    }
</script>

<style scoped>

</style>