<template>
    <div class="home">
        <navigator/>

        <div class="container" style="margin-top: 10px">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#/">
                    <font-awesome-icon icon="home" size="1x"/> HOME</a>
                </li>
                <li class="breadcrumb-item active">
                    <font-awesome-icon icon="building" size="1x"/>  USUÁRIOS
                </li>
            </ol>
            <a href="#/usuario" class="btn btn-success">
                NOVO USUÁRIO
            </a>
            <br/><br/>
            <list-usuarios/>
        </div>

    </div>
</template>

<script>
    import ListUsuarios from "../components/ListUsuarios";

    export default {
        name: "Usuarios",
        components: {
            ListUsuarios
        }
    }
</script>

<style scoped>

</style>
