<template>
    <div class="home">
        <navigator/>

        <div class="container" style="margin-top: 10px">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#/">Principal</a></li>
                <li class="breadcrumb-item"><a href="#/usuarios">Usuários</a></li>
                <li class="breadcrumb-item active">NOVO USUÁRIO</li>
            </ol>


            <form>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>EMAIL</label>
                            <input v-model="json.email" type="email" class="form-control" >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        A SENHA PADRAO É: <b>1234</b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 mt-3">
                        <button type="button" :disabled="! json.email" @click="trocar()" class="btn btn-block btn-success">
                            SALVAR USUÁRIO
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "UpdatePassword",
        data () {
            return {
                json: {
                    email: null,
                    id_usuario: null
                }
            }
        },
        beforeMount () {
            if (this.$route.params.id) {
                this.getEmpresa(this.$route.params.id)
            }
        },
        methods: {
            async getEmpresa (id) {
                let res = await axios.get("/users/" + id);
                this.json = res.data.payload.usuario;
            },
            async trocar () {
                try {
                    if (!this.json.id_usuario) {
                        await axios.post("/users", this.json);
                    } else {
                        await axios.put("/users/" + this.$route.params.id, this.json);
                    }

                    this.$swal('USUÁRIO!', "USUÁRIO SALVO COM SUCESSO", "success");
                    this.$router.push("/usuarios");
                } catch (e) {
                    this.$swal('USUÁRIO!', "PROBLEMAS PARA SALVAR", "error");
                }
            }
        }
    }
</script>

<style scoped>

</style>
