import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login';
import Usuarios from './views/Usuarios';
import UsuarioADD from './views/UsuarioADD';
import UpdatePassword from './views/UpdatePassword';
import Empresas from './views/Empresas';
import EmpresaADD from './views/EmpresaADD';

Vue.use(Router);

let checkAuth = (to, from, next) => {
    if (!window.sessionStorage.getItem('token')){
        return next({path: '/login'});
    }
    next();
};

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            beforeEnter: checkAuth
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/usuarios',
            name: 'usuarios',
            component: Usuarios,
            beforeEnter: checkAuth
        },
        {
            path: '/usuario/:id',
            name: 'usuario-edit',
            component: UsuarioADD,
            beforeEnter: checkAuth
        },
        {
            path: '/usuario',
            name: 'usuario-add',
            component: UsuarioADD,
            beforeEnter: checkAuth
        },
        {
            path: '/usuario/:id/password',
            name: 'usuario-update-password',
            component: UpdatePassword,
            beforeEnter: checkAuth
        },
        {
            path: '/empresas',
            name: 'empresas',
            component: Empresas,
            beforeEnter: checkAuth
        },
        {
            path: '/empresa',
            name: 'empresa',
            component: EmpresaADD,
            beforeEnter: checkAuth
        },
        {
            path: '/empresa/:id',
            name: 'empresa-edit',
            component: EmpresaADD,
            beforeEnter: checkAuth
        },
    ],
});
