<template>
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">NOME</th>
                <th style="width: 300px; text-align: center">TOKEN</th>
                <th style="width: 100px; text-align: center" scope="col">AÇÕES</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="em in empresas" :key="em.id_empresa_email">
                <th scope="row">{{em.nome}}</th>
                <th style="text-align: center" scope="row">{{em.token}}</th>
                <td style="text-align: center">
                    <a class="btn btn-warning btn-sm" :href="'#/empresa/' + em.id_empresa_email">
                        editar
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ListEmpresas",
        data () {
            return {
                empresas: []
            }
        },
        beforeMount () {
            this.list();
        },
        methods: {
            async list () {
                let res = await axios.get("/empresas");
                this.empresas = res.data.payload.empresas;
            }
        }
    }
</script>

<style scoped>

</style>