<template>
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">USUÁRIO</th>
                <th style="width: 200px; text-align: center" scope="col">AÇÕES</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="em in empresas" :key="em.id_usuario">
                <th scope="row">{{em.email}}</th>
                <td style="text-align: center">
                    <a class="btn btn-warning text-uppercase btn-sm mr-1" :href="'#/usuario/' + em.id_usuario">
                        Editar
                    </a>
                    <a class="btn btn-info text-uppercase btn-sm" :href="'#/usuario/' + em.id_usuario + '/password'">
                        Alterar Senha
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ListEmpresas",
        data () {
            return {
                empresas: []
            }
        },
        beforeMount () {
            this.list();
        },
        methods: {
            async list () {
                let res = await axios.get("/users");
                this.empresas = res.data.payload.empresas;
            }
        }
    }
</script>

<style scoped>

</style>
