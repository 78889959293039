<template>
    <div class="home">

        <navigator/>

        <div class="container" style="margin-top: 10px">

            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#/">Principal</a></li>
                <li class="breadcrumb-item active">EMPRESAS</li>
            </ol>

            <form>
                <fieldset>

                    <div class="row" style="margin-bottom: 10px">
                        <div class="col-5">
                            <div class="form-group">
                                <label for="exampleInputEmail1">NOME DA EMPRESA</label>
                                <input v-model="empresa.nome" type="text" class="form-control" id="exampleInputEmail1" placeholder="Informe o nome">
                            </div>
                        </div>
                        <div class="col-5" v-if="empresa.id_empresa_email">
                            <div class="form-group">
                                <label for="exampleInputEmail1">TOKEN DA EMPRESA</label>
                                <input v-model="empresa.token" type="text" class="form-control" id="exampleInputEmail1">
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label for="ativo">ATIVO</label>
                                <select v-model="empresa.ativo" class="form-control" id="ativo">
                                    <option :value="true">SIM</option>
                                    <option :value="false">NÃO</option>
                                </select>
                            </div>
                        </div>

                    </div>

                    <hr/>

                    <div class="row">
                        <div class="col-3">
                            <legend>SMTP</legend>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <label>HOST SMTP</label>
                                <input v-model="smtp.host" type="text" class="form-control" placeholder="Informe o nome">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label>USERNAME</label>
                                <input v-model="smtp.user" type="text" class="form-control" placeholder="Informe o nome">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label>PASSWORD</label>
                                <input v-model="smtp.pass" type="text" class="form-control" placeholder="Informe o nome">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-2">
                            <div class="form-group">
                                <label for="ssl">SSL</label>
                                <select v-model="smtp.ssl" class="form-control" id="ssl">
                                    <option :value="true">SIM</option>
                                    <option :value="false">NÃO</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>PORT</label>
                                <input v-model.number="smtp.port" type="number" class="form-control" placeholder="Informe o nome">
                            </div>
                        </div>
                        <div class="col-3" style="padding-top: 30px">
                            <button type="button" :disabled="!smtp.host || !smtp.user || !smtp.pass || !smtp.port" @click="add()" class="btn btn-block btn-success">
                                ADICIONAR SMTP
                            </button>
                        </div>
                    </div>

                    <br/>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">HOST</th>
                                    <th scope="col">USER</th>
                                    <th scope="col">PASS</th>
                                    <th scope="col">PORT</th>
                                    <th scope="col">SSL</th>
                                    <th scope="col" class="text-center" style="width: 200px">ACAO</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="s in empresa.smtp" :key="s.user">
                                    <th class="align-middle" scope="row">{{s.host}}</th>
                                    <td class="align-middle">{{s.user}}</td>
                                    <td class="align-middle">{{s.pass}}</td>
                                    <td class="align-middle">{{s.port}}</td>
                                    <td class="align-middle">{{s.ssl}}</td>
                                    <td>
                                        <button type="button"  @click="del(s)" class="btn btn-danger mr-2">
                                            APAGAR
                                        </button>
                                        <button type="button"  @click="smtp = s" class="btn btn-success">
                                            EDITAR
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3" style="margin-top: 30px">
                            <button type="button" :disabled="!empresa.nome" @click="save()" class="btn btn-block btn-success">
                                SALVAR EMPRESA
                            </button>
                        </div>

                        <div class="col-3" style="margin-top: 30px">
                          <button type="button" :disabled="!empresa.nome" @click="sendTest(empresa.id_empresa_email)" class="btn btn-block btn-danger">
                            TESTAR SMTP
                          </button>
                        </div>
                    </div>

                    <br/>

                </fieldset>
            </form>
        </div>

    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "EmpresaADD",
        data () {
            return {
                empresa: {
                    nome: null,
                    ativo: true,
                    smtp: []
                },
                smtp: {
                    host: null,
                    port: null,
                    ssl: false,
                    pass: null,
                    user: null
                }
            }
        },
        beforeMount () {
            if (this.$route.params.id) {
                this.getEmpresa(this.$route.params.id)
            }
        },
        methods: {
            add () {
                this.empresa.smtp.push(this.smtp);
                this.smtp = {
                    host: null,
                    port: null,
                    ssl: false,
                    pass: null,
                    user: null
                };
            },
            del (smtp) {
                this.empresa.smtp.splice(this.empresa.smtp.indexOf(smtp), 1);
            },
            async getEmpresa (id) {
                let res = await axios.get("/empresa/" + id);
                this.empresa = res.data.payload.empresa;
            },

            async sendTest (id) {
              await axios.post("/empresa/" + id + "/test");
              this.$swal('EMPRESA!', "TESTE ENVIADO SALVA COM SUCESSO", "success");
            },

            async save () {
                let emp = {
                    id_empresa_email: this.empresa.id_empresa_email,
                    smtp: this.empresa.smtp,
                    nome: this.empresa.nome,
                    ativo: this.empresa.ativo
                };

                if (!this.empresa.id_empresa_email) {
                    await axios.post("/empresa", emp);
                } else {
                    await axios.put("/empresa", emp);
                }
                this.$swal('EMPRESA!', "EMPRESA SALVA COM SUCESSO", "success");
                this.$router.push("/empresas");
            }
        }
    }
</script>

<style scoped>

</style>
