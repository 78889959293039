<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
        <div class="container">
            <a class="navbar-brand" href="#">
                <font-awesome-icon icon="at" /> <b>MAILER</b>
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarColor01">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link" href="#/empresas">
                            EMPRESAS
                        </a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="#/usuarios">
                            USUÁRIOS
                        </a>
                    </li>

                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/docs">
                            API
                        </a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" style="cursor: pointer" @click="sair()">
                            <b>SAIR</b>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    </nav>
</template>

<script>
    export default {
        name: "navigator",
        methods: {
            sair () {
                window.localStorage.clear();
                this.$router.push("/login");
            }
        }
    }
</script>

<style scoped>

</style>
