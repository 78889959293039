<template>
    <div class="home">
        <navigator/>

        <div class="container" style="margin-top: 10px">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#/">Principal</a></li>
                <li class="breadcrumb-item"><a href="#/usuarios">Usuários</a></li>
                <li class="breadcrumb-item active">ALTERAR SENHA</li>
            </ol>


            <form>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>NOVA SENHA</label>
                            <input v-model="json.password" type="password" class="form-control" >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>REPETIR A SENHA</label>
                            <input v-model="json.password2" type="password" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 mt-3">
                        <button type="button" :disabled="!json.password || ! json.password2" @click="trocar()" class="btn btn-block btn-success">
                            ALTERAR SENHA
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "UpdatePassword",
        data () {
            return {
                json: {
                    password: null,
                    password2: null
                }
            }
        },
        methods: {
            async trocar () {
                try {
                    await axios.put("/users/" + this.$route.params.id + "/password", this.json);
                    this.$swal('USUÁRIO!', "SENHA TROCADA COM SUCESSO", "success");
                    this.$router.push("/usuarios");
                } catch (e) {
                    this.$swal('USUÁRIO!', "PROBLEMAS PARA TROCAR A SENHA", "error");
                }
            }
        }
    }
</script>

<style scoped>

</style>
