<template>
    <div class="home">
        <navigator/>
    </div>
</template>
<script>
    export default {
        name: 'home'
    };
</script>
